<template>
  <v-container fluid>
    <PageNavigation :items="items" />
    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.siteInfo')"
          icon="mdi-apple-finder"
        >
          <v-sheet>
            <v-container fluid>
              <v-row>
                <v-col> </v-col>
              </v-row>

              <v-row>
                <!-- companyName -->
                <v-col class="py-0" cols="12" lg="2" md="3" sm="6">
                  <p class="font-weight-medium">
                    {{ $vuetify.lang.t("$vuetify.companyName") }}
                  </p>
                </v-col>
                <v-col class="py-0" cols="12" lg="2" md="3" sm="6">
                  <v-text-field outlined v-model="form.title" />
                </v-col>
              </v-row>
              <v-row>
                <!-- companyShortName -->
                <v-col class="py-0" cols="12" lg="2" md="3" sm="6">
                  <p class="font-weight-medium">
                    {{ $vuetify.lang.t("$vuetify.companyNameShort") }}
                  </p>
                </v-col>
                <v-col class="py-0" cols="12" lg="2" md="3" sm="6">
                  <v-text-field outlined v-model="form.short_title" />
                </v-col>
              </v-row>
              <v-row>
                <!-- Background Color -->
                <v-col class="py-0" cols="12" lg="2" md="3" sm="6">
                  <p class="font-weight-medium">
                    {{ $vuetify.lang.t("$vuetify.backgroundcolor") }}
                  </p>
                </v-col>
                <v-col class="py-0" cols="12" lg="2" md="3" sm="6">
                  <v-text-field v-model="color" solo outlined>
                    <template v-slot:append>
                      <v-menu
                        v-model="menu"
                        top
                        nudge-bottom="105"
                        nudge-left="16"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on }">
                          <div :style="paletteStyle" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text>
                            <v-color-picker flat v-model="color" mode="hexa" />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- Font_Color -->
              <!-- <v-row>
                <v-col class="py-0" cols="12" lg="2" md="3" sm="6">
                  <p class="font-weight-medium">
                    {{ $vuetify.lang.t("$vuetify.fontcolor") }}
                  </p>
                </v-col>
                <v-col class="py-0" cols="12" lg="2" md="3" sm="6">
                  <v-text-field v-model="font_color" solo outlined>
                    <template v-slot:append>
                      <v-menu
                        v-model="menu2"
                        top
                        nudge-bottom="105"
                        nudge-left="16"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on }">
                          <div :style="paletteStyle2" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text>
                            <v-color-picker
                              flat
                              v-model="font_color"
                              mode="hexa"
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row> -->

              <!-- Logo -->
              <v-row>
                <v-col class="py-0" cols="12" lg="2" md="3" sm="6">
                  <p class="font-weight-medium">
                    {{ $vuetify.lang.t("$vuetify.logo") }}
                  </p>
                </v-col>
                <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
                  <siteInfoImageUpload
                    :image.sync="uploadedLogo"
                    :avatar="form.logo"
                    :defaultImg="blankProfile"
                  />
                </v-col>
              </v-row>

              <!-- Background Color -->
              <!-- <v-row>
                <v-col class="py-0" cols="12" lg="2" md="3" sm="6">
                  <p class="font-weight-medium">
                    {{ $vuetify.lang.t("$vuetify.background") }}
                  </p>
                </v-col>
                <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
                  <siteInfoImageUpload
                    :image.sync="uploadedBackground"
                    :avatar="form.bg_img"
                    :defaultImg="blankProfile"
                  />
                </v-col>
              </v-row> -->

              <v-row>
                <v-col class="py-0" cols="12" lg="3" md="3" sm="6">
                  <v-btn color="#45af48" class="float-left" @click="submit">
                    {{ $vuetify.lang.t("$vuetify.confirm") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getSiteSetting, upsertSiteSetting } from "@/api/system";
import serverConfig from "@/utils/serverConfig";
import { profilePicUpload } from "@/api/company";

const newObj = () => {
  return {
    title: "",
    short_title: "",
    logo: "",
    bg_img: "",
    color: "",
    font_color: "",
  };
};

export default {
  name: "siteInfo",
  components: {
    PageNavigation: () => import("@/components/pageNavigation"),
    siteInfoImageUpload: () =>
      import("@/components/system/siteInfoImageUpload"),
  },
  data() {
    return {
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.sysMgr") },
        { text: this.$vuetify.lang.t("$vuetify.siteInfo") },
      ],
      form: newObj(),
      color: "",
      font_color: "",
      menu: false,
      menu2: false,
      uploadedLogo: undefined,
      uploadedBackground: undefined,
      blankProfile: serverConfig.blank_profile_img,
    };
  },
  methods: {
    getData() {
      getSiteSetting()
        .then((res) => {
          this.form = res.item;
          this.color = this.form.color;
          this.font_color = this.form.font_color;
        })
        .catch((err) => console.log(err));
    },
    async submit() {
      this.form.color = this.color;
      this.form.font_color = this.font_color;

      if (this.uploadedLogo != undefined) {
        await this.uploadFile(this.uploadedLogo)
          .then((data) => {
            this.form.logo = data;
          })
          .catch((err) => {
            console.log("Logo", err);
          });
      }

      if (this.uploadedBackground != undefined) {
        await this.uploadFile(this.uploadedBackground)
          .then((data) => {
            this.form.bg_img = data;
          })
          .catch((err) => {
            console.log("Background", err);
          });
      }
      upsertSiteSetting(this.form)
        .then(() => {
          this.$toast.success("Operation Successful");
          localStorage.setItem("color", this.form.color);
        })
        .catch((err) => {
          console.log("Err", err);
        });
    },
    async uploadFile(file) {
      var arr = "";

      const fileForm = new FormData();
      fileForm.append("file", file);

      await profilePicUpload(fileForm)
        .then((res) => {
          arr = res.data.md5;
        })
        .catch((err) => console.log("Finanical File Error", err));

      return arr;
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    paletteStyle() {
      const { color, menu } = this;
      return {
        backgroundColor: color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    paletteStyle2() {
      const { font_color, menu2 } = this;
      return {
        backgroundColor: font_color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu2 ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
  },
};
</script>

<style>
</style>